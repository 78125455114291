import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55aa2f89"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "slide-number bloc-number" }
const _hoisted_4 = { class: "slide-content" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_slide = _resolveComponent("slide")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_carousel = _resolveComponent("carousel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      "is-home": false,
      title: "Les bons reflexes",
      icon: "shield"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_carousel, {
        wrapAround: true,
        "onUpdate:modelValue": _ctx.changeSlide
      }, {
        addons: _withCtx(() => [
          _createVNode(_component_pagination)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, i) => {
            return (_openBlock(), _createBlock(_component_slide, { key: i }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: require('@/assets/tuto/' + slide.image),
                  alt: slide.alt,
                  class: "slide-image"
                }, null, 8, _hoisted_2),
                _createElementVNode("span", _hoisted_3, _toDisplayString(i + 1), 1),
                _createElementVNode("span", _hoisted_4, [
                  (slide.title)
                    ? (_openBlock(), _createElementBlock("strong", {
                        key: 0,
                        class: "slide-title",
                        innerHTML: slide.title
                      }, null, 8, _hoisted_5))
                    : _createCommentVNode("", true),
                  (slide.content)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        innerHTML: slide.content
                      }, null, 8, _hoisted_6))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["onUpdate:modelValue"])
    ])
  ], 64))
}