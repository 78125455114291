
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import '@/assets/scss/_carousel.scss'
import '@/assets/scss/_bloc-number.scss'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  emits: ['initFullScreen'],
  setup() {
    const { t, n } = useI18n()
    return {
      t,
      n,
      slides: [
        {
          title: t('modalslides.tuto1.title'),
          content: null,
          image: 'tuto-1.svg',
          alt: t('modalslides.tuto1.alt')
        },
        {
          title: t('modalslides.tuto2.title'),
          content: null,
          image: 'tuto-2.svg',
          alt: t('modalslides.tuto2.alt')
        },
        {
          title: t('modalslides.tuto3.title'),
          content: null,
          image: 'tuto-3.svg',
          alt: t('modalslides.tuto3.alt')
        },
        {
          title: t('modalslides.tuto4.title'),
          content: null,
          image: 'tuto-4.svg',
          alt: t('modalslides.tuto4.alt')
        },
        {
          title: t('modalslides.tuto5.title'),
          content: t('modalslides.tuto5.content'),
          image: 'tuto-5.svg',
          alt: t('modalslides.tuto5.alt')
        },
        {
          title: t('modalslides.tuto6.title'),
          content: t('modalslides.tuto6.content'),
          image: 'tuto-6.svg',
          alt: t('modalslides.tuto6.alt')
        },
        {
          title: t('modalslides.tuto7.title'),
          content: t('modalslides.tuto7.content'),
          image: 'tuto-7.svg',
          alt: t('modalslides.tuto7.alt')
        },
        {
          title: t('modalslides.tuto8.title'),
          content: t('modalslides.tuto8.content'),
          image: 'tuto-8.svg',
          alt: t('modalslides.tuto8.alt')
        }
        // {
        //   title: 'Le dépanneur arrive sur place :',
        //   content: 'n’allez pas à sa rencontre et restez derrière la glissière de sécurité.',
        //   image: 'reflexe-1.svg',
        //   alt: 'Un véhicule est en panne sur le bord de la route.'
        // },
        // {
        //   title: 'Le dépanneur fait une première analyse',
        //   content: 'et détermine la nature de l’intervention.',
        //   image: 'reflexe-2.svg',
        //   alt: 'Un dépanneur inspecte le véhicule en panne.'
        // },
        // {
        //   title: 'Cela permet de déterminer le lieu de la réparation',
        //   content: '(sur place, sur une aire proche ou dans le garage du dépanneur - la plupart du temps - à moins de 30 minutes de route).',
        //   image: 'reflexe-3.svg',
        //   alt: 'Le dépanneur explique le trajet jusqu\'au garage'
        // },
        // {
        //   title: 'Votre véhicule est chargé sur le camion du dépanneur',
        //   content: 'et vous prenez place dans la dépanneuse.',
        //   image: 'reflexe-4.svg',
        //   alt: 'Le dépanneur charge le véhicule en panne sur sa dépanneuse'
        // },
        // {
        //   title: 'Pensez à prendre votre badge télépéage ou votre ticket de péage et moyen de paiement.',
        //   content: 'Les frais de péage restent à votre charge.',
        //   image: 'reflexe-5.svg',
        //   alt: 'Le dépanneur explique aux personnes en panne qu\'ils doivent payer le trajet d\'autoroute'
        // },
        // {
        //   title: 'Vous appelez votre assistance,',
        //   content: 'lors du trajet ou à l’arrivée au garage, avec l’aide du dépanneur.',
        //   image: 'reflexe-6.svg',
        //   alt: 'Un smartphone en appel avec l\'assistance'
        // },
        // {
        //   title: 'Au garage, le dépanneur fait un diagnostic plus approfondi',
        //   content: '(réparation ou transfert dans un autre garage ou concession).',
        //   image: 'reflexe-7.svg',
        //   alt: 'Le dépanneur branche la voiture en panne à un outil de dignostique electronique'
        // },
        // {
        //   title: 'Le dépanneur vous accompagne dans vos démarches pour la suite de votre voyage',
        //   content: '(dossier avec l’assureur, réservation d’un hôtel, taxi ou véhicule de remplacement ou de location).',
        //   image: 'reflexe-8.svg',
        //   alt: 'Le dépanneur rends les clefs du véhicule aux propriétaires'
        // }
      ]

    }
  },
  data() {
    return {
      lastSlide: 0
    }
  },
  methods: {
    changeSlide(slideNumber) {
      if (this.lastSlide > 1 && slideNumber === 0) {
        this.$router.push('Home')
      }
       this.lastSlide = slideNumber
    }
  },
  components: {
    Header,
    Carousel,
    Slide,
    Pagination
  }
})
